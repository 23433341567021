/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
.k-theme-test-class,
.ktb-theme-id-nova {
  opacity: 0;
}
.ktb-var-accent {
  color: #ff4350;
}
.ktb-var-base {
  color: #fff;
}
.ktb-var-background {
  color: #fff;
}
.ktb-var-border-radius {
  border-radius: 0;
}
.ktb-var-normal-background {
  color: #fff;
}
.ktb-var-normal-gradient {
  background-image: none;
}
.ktb-var-normal-text-color {
  color: #32364c;
}
.ktb-var-hover-background {
  color: #f5f6f6;
}
.ktb-var-hover-gradient {
  background-image: none;
}
.ktb-var-hover-text-color {
  color: #888e90;
}
.ktb-var-selected-background {
  color: #ff4350;
}
.ktb-var-selected-gradient {
  background-image: none;
}
.ktb-var-selected-text-color {
  color: #fff;
}
.ktb-var-error {
  color: #ffbfc4;
}
.ktb-var-warning {
  color: #ffecc7;
}
.ktb-var-success {
  color: #a5d6a7;
}
.ktb-var-info {
  color: #80deea;
}
.ktb-var-series-a {
  color: #ff4350;
}
.ktb-var-series-b {
  color: #ff9ea5;
}
.ktb-var-series-c {
  color: #00acc1;
}
.ktb-var-series-d {
  color: #80deea;
}
.ktb-var-series-e {
  color: #ffbf46;
}
.ktb-var-series-f {
  color: #ffd78c;
}
.k-grid-norecords-template {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}
/* Main colors */
.k-in,
.k-item,
.k-window-action {
  border-color: transparent;
}
.k-block,
.k-widget {
  background-color: #fff;
}
.k-block,
.k-widget,
.k-input,
.k-textbox,
.k-group,
.k-content,
.k-header,
.k-filter-row > th,
.k-editable-area,
.k-separator,
.k-colorpicker .k-i-arrow-s,
.k-textbox > input,
.k-autocomplete,
.k-dropdown-wrap,
.k-toolbar,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-state-default,
.k-state-disabled,
.k-grid-header,
.k-grid-header-wrap,
.k-grid-header-locked,
.k-grid-footer-locked,
.k-grid-content-locked,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-footer-wrap,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-refresh,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-panel,
.k-panelbar .k-content,
.k-treemap-tile,
.k-calendar th,
.k-slider-track,
.k-splitbar,
.k-dropzone-active,
.k-tiles,
.k-toolbar,
.k-tooltip,
.k-button-group .k-tool,
.k-upload-files {
  border-color: #e0e0e0;
}
.k-group,
.k-toolbar,
.k-grouping-header,
.k-pager-wrap,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-widget .k-status,
.k-calendar th,
.k-dropzone-hovered,
.k-widget.k-popup {
  background-color: #fff;
}
.k-header,
.k-grid-header,
.k-grouping-header,
.k-pager-wrap,
.k-state-highlight,
.k-panelbar .k-tabstrip-items .k-item {
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-block,
.k-widget,
.k-popup,
.k-content,
.k-toolbar,
.k-dropdown .k-input {
  color: #32364c;
}
.k-popup,
.k-menu .k-menu-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-tooltip {
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
}
.k-content,
.k-editable-area,
.k-panelbar > li.k-item,
.k-panel > li.k-item,
.k-tiles {
  background-color: #fff;
}
.k-alt,
.k-separator,
.k-resource.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
  background-color: #fff;
}
.k-icon,
.k-tool-icon,
.k-grouping-dropclue,
.k-drop-hint,
.k-column-menu .k-sprite,
.k-grid-mobile .k-resize-handle-inner:before,
.k-grid-mobile .k-resize-handle-inner:after,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
  background-image: url('Nova/sprite.png');
  border-color: transparent;
}
.k-input,
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input,
.k-multiselect-wrap {
  color: #32364c;
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-state-selected {
  background-color: rgba(128, 222, 234, 0.25);
}
.k-state-focused {
  -webkit-box-shadow: inset 0 0 0 1px #878787;
          box-shadow: inset 0 0 0 1px #878787;
}
.k-state-hover,
.k-state-hover:hover,
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover,
.k-list > .k-state-hover,
.k-pager-wrap .k-link:hover,
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone {
  color: #888e90;
  background-color: #f5f6f6;
  border-color: #f5f6f6;
}
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-button,
.k-dropzone em,
.k-tile-empty strong,
.k-slider .k-draghandle {
  color: #f0f0f0;
}
/* Border radius */
.k-block,
.k-drag-clue,
.k-touch-scrollbar,
.k-window,
.k-window-titleless .k-window-content,
.k-window-action,
.k-inline-block,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-notification,
.k-treeview .k-in,
.k-editor-inline,
.k-tile,
.k-slider-track,
.k-slider-selection {
  border-radius: 0;
}
.k-button,
.k-textbox,
.k-autocomplete,
.k-autocomplete .k-input,
.k-multiselect,
.k-combobox,
.k-dropdown,
.k-dropdown-wrap,
.k-datepicker,
.k-timepicker,
.k-colorpicker,
.k-datetimepicker,
.k-numerictextbox,
.k-picker-wrap,
.k-numeric-wrap,
.k-colorpicker,
.k-tooltip,
.k-upload {
  border-radius: 3px;
}
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-toolbar .k-split-button .k-button {
  border-radius: 3px 0 0 3px;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0 3px 3px 0;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input {
  border-radius: 0 3px 3px 0;
}
/* Default icons opacity */
.k-icon,
.k-button .k-image,
.k-panelbar > .k-state-active > .k-header > .k-icon,
.k-panelbar > .k-state-active > .k-header .k-state-default .k-icon,
.k-tabstrip-scrollable .k-button:active .k-icon {
  opacity: .4;
}
.k-primary .k-icon,
.k-state-active .k-icon,
.k-button:active .k-icon,
.k-numerictextbox .k-state-selected .k-icon,
.k-multiselect-wrap .k-icon,
.k-calendar .k-header .k-icon,
.k-window-titlebar .k-icon,
.k-drag-clue .k-icon,
.k-group-indicator .k-icon,
.k-pivot-toolbar .k-icon,
.k-grid-content .k-button:active .k-icon,
.k-splitbar.k-state-focused .k-icon,
.k-scheduler-toolbar .k-icon,
.k-gantt-toolbar .k-icon,
.k-toolbar .k-overflow-anchor.k-state-border-down .k-i-more,
.k-panelbar > .k-state-active > .k-header .k-state-active .k-icon {
  opacity: 1;
}
.k-state-disabled .k-icon {
  opacity: .2;
}
/* Button */
.k-button {
  color: #9da2a4;
  border-color: #9da2a4;
  background-color: #fff;
}
.k-button:focus,
.k-button.k-state-focused,
.k-split-button:focus {
  border-color: #ff4350;
  outline: 0;
}
.k-button:hover,
.k-button.k-state-hover {
  background-color: #f5f6f6;
}
.k-button:active,
.k-button.k-state-active {
  color: #ffffff;
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-button[disabled],
.k-button.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  color: #c4c4c4;
  border-color: #bdc0c1;
  background-color: #fff;
}
/* Primary Button */
.k-primary,
.k-overflow-container .k-primary {
  color: #ffffff;
  border-color: #ff4350;
  background-color: #ff4350;
}
.k-primary:focus,
.k-primary.k-state-focused {
  border-color: #e43c48;
}
.k-primary:hover,
.k-primary:active {
  color: #ffffff;
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #fff;
  border-color: #ff9fa5;
  background-color: #ff9fa5;
}
/* Menu */
.k-menu {
  background-color: #fff;
  border-color: #fff;
}
.k-menu > .k-item.k-state-hover {
  color: #32364c;
}
.k-menu .k-popup .k-item {
  color: #00acc1;
}
.k-menu .k-item > .k-state-active {
  color: #fff;
  background-color: #ff4350;
}
/* Inputs */
.k-list-container {
  border-color: #e0e0e0;
  background-color: #fff;
}
.k-dropdown,
.k-combobox,
.k-datepicker,
.k-datetimepicker,
.k-colorpicker {
  background-color: #fff;
}
.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-multiselect.k-header {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  background-color: #f5f6f6;
}
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  background-color: #fff;
  border-color: #9da2a4;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-multiselect.k-header.k-state-active {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-textbox.k-state-disabled,
.k-state-disabled .k-picker-wrap,
.k-autocomplete.k-state-disabled,
.k-dropdown-wrap.k-state-disabled,
.k-picker-wrap.k-state-disabled,
.k-multiselect.k-state-disabled,
.k-numeric-wrap.k-state-disabled {
  background-color: #fff;
  border-color: #f0f0f0;
}
.k-numerictextbox .k-select,
.k-combobox .k-select,
.k-picker-wrap .k-select {
  border-color: #fff;
}
.k-autocomplete.k-state-default.k-state-border-up,
.k-autocomplete.k-state-default.k-state-border-down,
.k-picker-wrap.k-state-active .k-select,
.k-numeric-wrap.k-state-active .k-select,
.k-dropdown-wrap.k-state-active .k-select {
  border-color: #ff4350;
}
.k-numeric-wrap .k-link.k-state-selected {
  background-color: #ff4350;
}
.k-dropdown .k-state-active .k-input {
  color: #fff;
}
.k-colorpicker .k-selected-color {
  background-color: #fff;
}
input.k-textbox:focus {
  border-color: #9da2a4;
}
input.k-textbox:active {
  border-color: #ff4350;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  background: #ff4350;
  color: #fff;
}
.k-popup .k-list .k-item {
  color: #00acc1;
}
.k-popup .k-list .k-item > .k-group {
  background: #ff4350;
  color: #fff;
}
.k-popup .k-list .k-state-selected {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-popup .k-list .k-state-focused {
  border-color: #ff4350;
}
.k-multiselect .k-button {
  color: #ffffff;
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-multiselect.k-state-focused.k-state-border-up,
.k-multiselect.k-state-focused.k-state-border-down {
  border-color: #ff4350;
}
.k-multiselect.k-header.k-state-focused .k-button,
.k-multiselect.k-state-focused.k-state-border-up .k-button,
.k-multiselect.k-state-focused.k-state-border-down .k-button {
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-multiselect.k-header.k-state-hover .k-button,
.k-multiselect .k-button:hover,
.k-multiselect .k-button.k-state-hover {
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-list-optionlabel {
  border-color: transparent;
}
/* Calendar */
.k-calendar th {
  color: #9da2a4;
}
.k-calendar td {
  border-radius: 50%;
  border-color: transparent;
}
.k-calendar .k-content .k-link,
.k-calendar .k-footer .k-link {
  color: #00acc1;
}
.k-calendar .k-header .k-link,
.k-calendar .k-state-selected .k-link {
  color: #ffffff;
}
.k-calendar .k-state-hover {
  border-color: #f5f6f6;
}
.k-calendar .k-state-focused {
  border-color: #9da2a4;
}
.k-calendar .k-header .k-state-hover {
  background-color: #e43c48;
}
.k-calendar .k-other-month,
.k-calendar .k-other-month .k-link {
  color: #c5c8c9;
}
.k-calendar .k-today {
  background-color: #ff4350;
}
.k-calendar .k-today .k-link {
  color: #fff;
}
.k-calendar .k-today.k-state-hover {
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-calendar .k-state-selected,
.k-calendar .k-state-selected.k-state-hover,
.k-calendar .k-today.k-state-focused,
.k-calendar .k-today:active,
.k-calendar .k-today.k-state-selected:active {
  background-color: #ff4350;
  border-color: #ff4350;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-calendar .k-state-selected.k-state-hover {
  background-color: #e43c48;
}
.k-calendar .k-state-selected.k-state-focused {
  border-color: #9a2830;
}
.k-calendar .k-footer {
  border-color: #e0e0e0;
}
/* Notification */
.k-widget.k-notification.k-notification-info {
  color: #21b6c9;
  background-color: #80deea;
  border-color: #80deea;
}
.k-widget.k-notification.k-notification-success {
  color: #4fad53;
  background-color: #a5d6a7;
  border-color: #a5d6a7;
}
.k-widget.k-notification.k-notification-warning {
  color: #ffc147;
  background-color: #ffecc7;
  border-color: #ffecc7;
}
.k-widget.k-notification.k-notification-error {
  color: #ff404e;
  background-color: #ffbfc4;
  border-color: #ffbfc4;
}
/* Tooltip */
.k-widget.k-tooltip {
  color: #32364c;
  background-color: #fff;
  border-color: #e0e0e0;
  -webkit-box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
}
.k-widget.k-tooltip-validation {
  color: #ffc147;
  background-color: #ffecc7;
  border-color: #ffecc7;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}
/* TreeView */
.k-treeview .k-state-hover {
  color: #32364c;
}
.k-treeview .k-state-selected {
  color: #ff4350;
}
/* Splitter */
.k-splitbar {
  background: #fff;
  border-color: #fff;
}
.k-splitbar.k-state-focused {
  background-color: #ff4350;
  border-color: #ff4350;
}
/* Window */
.k-window {
  -webkit-box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
}
.k-window > .k-header {
  color: #ffffff;
}
.k-window > .k-header .k-state-hover {
  background-color: #e43c48;
}
/* PanelBar */
.k-panelbar {
  color: #32364c;
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-panelbar > .k-item .k-panel > .k-item > .k-link,
.k-panelbar > .k-item .k-panel > .k-item .k-panel > .k-item > .k-link,
.k-panelbar .k-panel > .k-item .k-panel .k-item > .k-link {
  color: #32364c;
}
.k-panelbar .k-item > .k-link.k-header {
  color: #ff4350;
  background-color: #fff;
}
.k-panelbar > .k-item > .k-link.k-state-hover,
.k-panelbar > .k-item .k-panel > .k-item > .k-link.k-state-hover {
  background-color: #f5f6f6;
}
/* TabStrip */
.k-tabstrip.k-header {
  background-color: #fff;
}
.k-tabstrip-items {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#303553), to(#072138));
  background-image: none, -webkit-linear-gradient(top, #303553 0%, #072138 100%);
  background-image: none, linear-gradient(to bottom, #303553 0%, #072138 100%);
}
.k-tabstrip-items .k-item {
  color: #ffffff;
  border-color: transparent;
}
.k-tabstrip-items .k-item.k-state-hover,
.k-tabstrip-items .k-item.k-state-selected {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#202333), to(#081421));
  background-image: none, -webkit-linear-gradient(top, #202333 0%, #081421 100%);
  background-image: none, linear-gradient(to bottom, #202333 0%, #081421 100%);
}
.k-tabstrip-left > .k-tabstrip-items > .k-item.k-state-active {
  border-right-color: #ff4350;
}
.k-tabstrip-right > .k-tabstrip-items > .k-item.k-state-active {
  border-left-color: #ff4350;
}
.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#202333), to(#081421));
  background-image: none, -webkit-linear-gradient(top, #202333 0%, #081421 100%);
  background-image: none, linear-gradient(to bottom, #202333 0%, #081421 100%);
  border-bottom-color: #ff4350;
}
.k-tabstrip-bottom > .k-tabstrip-items > .k-item.k-state-active {
  border-top-color: #ff4350;
}
/* ProgressbBar */
.k-progressbar {
  background: #cccccc;
  border-color: #cccccc;
}
.k-progressbar .k-state-selected {
  background-color: #ff4350;
}
.k-progressbar-horizontal .k-state-selected {
  border-top-color: #ff4350;
  border-bottom-color: #ff4350;
  border-left-color: #e0e0e0;
  border-right-color: #e0e0e0;
}
.k-progressbar-horizontal .k-state-selected.k-first {
  border-left-color: #ff4350;
}
.k-progressbar-horizontal .k-state-selected.k-last {
  border-right-color: #ff4350;
}
.k-progressbar-vertical .k-state-selected {
  border-left-color: #ff4350;
  border-right-color: #ff4350;
  border-top-color: #e0e0e0;
  border-bottom-color: #e0e0e0;
}
.k-progressbar-vertical > .k-complete {
  border-top-color: #ff4350;
  border-bottom-color: #ff4350;
}
.k-progressbar-vertical .k-state-selected.k-first {
  border-top-color: #ff4350;
}
.k-progressbar-vertical .k-state-selected.k-last {
  border-bottom-color: #ff4350;
}
/* Grid */
.k-grid-header,
.k-grid-header .k-header,
.k-pager-wrap,
.k-pager-numbers .k-state-selected,
.k-grid-footer,
.k-grid-footer td,
.k-scheduler-header,
.km-pane-wrapper .k-grid-header .k-header {
  background-color: #fafafa;
  border-color: #e0e0e0;
}
.k-grid-header .k-header .k-link,
.k-grid-header .k-header,
.k-grid-header .k-link,
.k-grid-header .k-link:link,
.k-pager-info,
.k-scheduler-header,
.k-scheduler-agendaview .k-scheduler-datecolumn {
  color: #9da2a4;
}
.k-grid .k-alt {
  background-color: #fff;
}
.k-grid tr:hover {
  background-color: #f5f6f6;
}
.k-grid-header th.k-state-focused,
.k-grid td.k-state-focused {
  -webkit-box-shadow: inset 0 0 0 1px #9c9c9c;
          box-shadow: inset 0 0 0 1px #9c9c9c;
}
.k-grid-toolbar .k-button,
.k-grid-content .k-button {
  color: #ff4350;
  border-color: transparent;
}
.k-grid-toolbar .k-button-icontext .k-icon,
.k-grid-content .k-button-icontext .k-icon,
.k-edit-form-container .k-edit-buttons .k-button-icontext .k-icon {
  display: none;
}
.k-grid-toolbar .k-button:hover {
  border-color: #f5f6f6;
}
.k-grid-content .k-button-icontext {
  background-color: transparent;
}
.k-grid-content .k-primary {
  color: #ffffff;
  background-color: #ff4350;
}
.k-grid-toolbar .k-button:active,
.k-grid-content .k-button-icontext:active {
  color: #ffffff;
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-grid-content .k-button:active {
  border-color: #ff4350;
}
.k-header.k-grid-toolbar {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-grid .k-grouping-header {
  color: rgba(255, 255, 255, 0.5);
}
.k-grouping-header .k-group-indicator {
  color: #ffffff;
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-group-indicator .k-link {
  color: #ffffff;
}
.k-grouping-row {
  background-color: #fafafa;
  font-weight: bold;
}
.k-group-cell {
  background-color: #fafafa;
}
.k-filter-menu .k-button,
.k-edit-form-container .k-button {
  color: #32364c;
  background: transparent;
  border-color: transparent;
}
.k-filter-menu .k-button:active {
  color: #32364c;
}
.k-filter-menu .k-primary,
.k-edit-form-container .k-primary {
  color: #ff4350;
}
.k-filter-menu .k-primary {
  border-left-color: #e0e0e0;
}
.k-filter-menu > div > div:last-child {
  border-color: #e0e0e0;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  border-color: rgba(224, 224, 224, 0.8);
}
.k-grid tr.k-state-selected,
.k-grid td.k-state-selected,
.k-grid td.k-state-selected.k-state-focused {
  background-color: #e0f7fa;
}
.k-grid tr:hover .k-state-selected,
.k-grid tr.k-state-selected:hover td {
  background-color: rgba(128, 222, 234, 0.25);
}
.k-grid tr:hover .k-state-focused.k-state-selected,
.k-grid tr.k-state-selected:hover td.k-state-focused {
  -webkit-box-shadow: inset 0 0 0 1px #878787;
          box-shadow: inset 0 0 0 1px #878787;
}
.k-marquee-color {
  background-color: #86d9e4;
}
/* PivotGrid */
.k-alt,
.k-grid.k-alt,
.k-fieldselector .k-item.k-header {
  background-color: #fafafa;
  border-color: #e0e0e0;
}
.k-pivot-toolbar .k-button {
  color: #ffffff;
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-pivot-toolbar .k-empty {
  color: rgba(255, 255, 255, 0.5);
}
.k-fieldselector .k-item.k-header.k-state-hover {
  color: #32364c;
}
.k-grid-footer td,
.k-pivot-rowheaders .k-grid-footer {
  font-weight: bold;
}
/* Pager */
.k-pager-wrap,
.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #32364c;
}
.k-pager-wrap .k-link {
  border-color: #fafafa;
}
.k-pager-wrap .k-link:hover {
  background-color: transparent;
  border-color: transparent;
}
.k-pager-numbers .k-state-selected {
  color: #ff4350;
  border-color: #ff4350 transparent transparent;
  border-radius: 0;
}
/* Scheduler */
.k-scheduler th,
.k-scheduler tr,
.k-scheduler td {
  border-color: #e0e0e0;
}
.k-scheduler-table td.k-state-selected {
  background-color: #e0f7fa;
}
.k-scheduler-toolbar {
  border-color: #fafafa;
}
.k-scheduler .k-header .k-button,
.k-scheduler .k-header li,
.k-scheduler .k-header .k-link {
  color: #ffffff;
}
.k-scheduler .k-header .k-button {
  color: #ffffff;
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-scheduler .k-header li,
.k-scheduler .k-header .k-link,
.k-gantt > .k-header li {
  border-color: #ff4350;
}
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-state-selected {
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-scheduler .k-header .k-button:hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover,
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover .k-link {
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-selected.k-state-hover,
.k-scheduler-toolbar > .k-scheduler-views > li.k-state-selected {
  border-bottom-color: #fff;
}
.k-scheduler-footer {
  border-color: #e0e0e0;
}
.k-scheduler-footer > .k-header li {
  border-color: transparent;
}
.k-scheduler-footer > .k-header {
  background-color: #fafafa;
  border-color: #e0e0e0;
}
.k-scheduler-footer > .k-header .k-link {
  color: #00acc1;
}
.k-event,
.k-task-complete {
  color: #ffffff;
  background: #24c6db;
  border-color: #24c6db;
}
.k-event.k-state-selected {
  -webkit-box-shadow: 0 0 0 2px #32364c;
          box-shadow: 0 0 0 2px #32364c;
}
/* Gantt */
.k-gantt .k-treelist,
.k-gantt .k-treelist .k-alt,
.k-gantt .k-header.k-nonwork-hour,
.k-gantt .k-treelist .k-alt,
.k-gantt .k-header.k-nonwork-hour {
  background-color: #fafafa;
}
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td {
  background-color: #e0f7fa;
}
.k-gantt-toolbar {
  border-color: #fafafa;
}
.k-gantt-toolbar .k-button {
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-gantt > .k-header li,
.k-gantt > .k-header .k-link,
.k-gantt-toolbar .k-button {
  color: #ffffff;
}
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover,
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover .k-link,
.k-gantt .k-gantt-toolbar .k-button:hover {
  background-color: #e43c48;
  border-color: #e43c48;
}
.k-gantt-toolbar > .k-gantt-views > li.k-state-selected,
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-selected.k-state-hover {
  border-bottom-color: #fff;
}
.k-gantt-toolbar li:first-child,
.k-gantt-toolbar li:first-child > .k-link,
.k-gantt-toolbar li:last-child,
.k-gantt-toolbar li:last-child > .k-link {
  border-radius: 0;
}
.k-gantt .k-task-draghandle {
  border-color: #24c6db;
}
.k-task-dot:after {
  background-color: #9da2a4;
  border-color: #9da2a4;
}
.k-task-dot:hover:after {
  background-color: #32364c;
  border-color: #32364c;
}
.k-task-summary {
  background: #e0e0e0;
}
.k-task-milestone {
  background-color: #4fad53;
}
.k-task-summary-complete {
  background: #32364c;
}
.k-state-selected.k-task-summary {
  background: #92e3ed;
}
.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete {
  background-color: #e0f7fa;
  border-color: #e0f7fa;
}
.k-task-single {
  color: #ffffff;
  background-color: #92e3ed;
  border-color: #92e3ed;
}
.k-state-selected.k-task-single {
  border-color: #ff4350;
}
.k-line {
  background-color: #32364c;
  color: #32364c;
}
.k-state-selected.k-line {
  background-color: #ff4350;
  color: #ff4350;
}
.k-resource {
  background-color: #fff;
}
/* Editor */
.k-editor.k-header,
.editorToolbarWindow.k-header,
.k-filebrowser .k-header {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-editor .k-tool.k-state-selected,
.k-window .k-editor-toolbar .k-tool.k-state-selected {
  background-color: #9da2a4;
  border-color: #9da2a4;
}
.k-window .k-editor-toolbar .k-state-hover {
  background-color: #f5f6f6;
}
/* Toolbar */
.k-toolbar .k-button,
.k-toolbar .k-button-group {
  color: #32364c;
  border-color: #e0e0e0;
}
.k-toolbar .k-button:active,
.k-toolbar .k-button.k-state-active {
  color: #ffffff;
}
.k-toolbar .k-button-group .k-button {
  border-color: transparent;
}
.k-toolbar .k-button-group .k-button:active,
.k-toolbar .k-button-group .k-button.k-state-active,
.k-toolbar .k-overflow-anchor.k-state-active,
.k-toolbar .k-toggle-button.k-state-active {
  background-color: #9da2a4;
}
.k-toolbar .k-overflow-anchor.k-state-active {
  border-color: #9da2a4;
}
.k-toolbar .k-overflow-anchor.k-state-border-down,
.k-toolbar .k-overflow-anchor.k-state-border-up {
  background-color: #ff4350;
  border-color: #ff4350;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  color: #00acc1;
}
.k-overflow-container .k-overflow-button.k-state-active,
.k-split-container .k-button.k-state-active {
  color: #fff;
}
/* Upload */
.k-file,
.k-upload {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-dropzone .k-upload-status {
  color: #9da2a4;
}
.k-file-progress {
  color: #32364c;
}
.k-file-progress .k-progress {
  background-color: #ff4350;
}
.k-file-success {
  color: #32364c;
}
.k-file-success .k-progress {
  background-color: #e0f7fa;
}
.k-file-error {
  color: #ffffff;
}
.k-file-error .k-progress {
  background-color: #ff4350;
}
/* Slider */
.k-slider-track {
  background-color: #e0e0e0;
}
.k-slider-selection {
  background-color: #ff4350;
}
.k-slider-horizontal .k-tick {
  background-image: url('Nova/slider-h.gif');
}
.k-slider-vertical .k-tick {
  background-image: url('Nova/slider-v.gif');
}
.k-draghandle {
  background-color: #fff;
  border-color: #e0e0e0;
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.k-draghandle:hover {
  background-color: #f5f6f6;
}
/* ColorPicker */
.k-flatcolorpicker .k-hue-slider .k-draghandle {
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus {
  background-color: #f5f6f6;
  border-color: #f5f6f6;
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
}
.k-checkbox-label:before {
  border-color: #e0e0e0;
  background: #fff;
  border-radius: 3px;
}
.k-checkbox-label:hover:before,
.k-checkbox:checked + .k-checkbox-label:hover:before {
  border-color: #c2c2c2;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:checked + .k-checkbox-label:before {
  background-color: #fff;
  border-color: #e0e0e0;
  color: #ff4350;
}
.k-checkbox-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #ff4350;
}
.k-checkbox:checked + .k-checkbox-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #ff4350;
}
.k-checkbox:disabled + .k-checkbox-label {
  color: #b5b5b5;
}
.k-checkbox:disabled + .k-checkbox-label:hover:before {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:active:before,
.k-checkbox:checked:disabled + .k-checkbox-label:hover:before {
  color: #b5b5b5;
  background: #fff;
  border-color: #e0e0e0;
  border-radius: 3px;
}
.k-checkbox:focus + .k-checkbox-label:before {
  border-color: #ff4350;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  background-color: #ff4350;
  background-image: none;
  border-color: #ff4350;
  border-radius: 0;
}
.k-checkbox:indeterminate:hover + .k-checkbox-label:after {
  border-color: #ff4350;
  background-color: #ff4350;
}
.k-radio-label:before {
  border-color: #e0e0e0;
  border-radius: 50%;
  background-color: #fff;
  border-width: 1px;
}
.k-radio-label:hover:before,
.k-radio:checked + .k-radio-label:hover:before {
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:checked + .k-radio-label:after {
  background-color: #ff4350;
  border-radius: 50%;
}
.k-radio-label:active:before {
  border-color: #ff4350;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:checked + .k-radio-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #ff4350;
}
.k-radio:disabled + .k-radio-label {
  color: #e6e6e6;
}
.k-radio:disabled + .k-radio-label:before,
.k-radio:disabled + .k-radio-label:active:before,
.k-radio:disabled + .k-radio-label:hover:after,
.k-radio:disabled + .k-radio-label:hover:before {
  background: #fff;
  border-color: #e0e0e0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:focus + .k-radio-label:before {
  border-color: #ff4350;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:checked + .k-radio-label:hover:before,
.k-radio:checked + .k-radio-label:active:before {
  border-color: #ff4350;
}
/* Drag & Drop */
.k-drag-clue {
  color: #ffffff;
  -webkit-box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
}
/* Adaptive Grid */
.k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #e0e0e0;
}
.km-header {
  color: #ff4350;
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
}
.km-pane-wrapper > .km-pane > .km-view > .km-content {
  background-color: #fafafa;
}
.km-header .k-button,
.km-header .k-button:hover {
  color: #ff4350;
  border-color: #fff;
  background-color: #fff;
}
html .km-pane-wrapper .km-widget,
.k-ie .km-pane-wrapper .k-widget,
.k-ie .km-pane-wrapper .k-group,
.k-ie .km-pane-wrapper .k-content,
.k-ie .km-pane-wrapper .k-header,
.k-ie .km-pane-wrapper .k-popup-edit-form .k-edit-field .k-button,
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-edit-field {
  color: #32364c;
}
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  div.km-pane-wrapper a {
    color: #32364c;
  }
  .km-pane-wrapper .k-icon {
    background-image: url('Nova/sprite_2x.png');
    -webkit-background-size: 21.2em 21em;
            background-size: 21.2em 21em;
  }
}
.km-pane-wrapper .k-mobile-list .k-check,
.km-pane-wrapper .k-mobile-list .k-edit-field textarea {
  outline: none;
}
.km-pane-wrapper .k-mobile-list .k-item.k-state-selected {
  color: #fff;
  background-color: #ff4350;
  border-top-color: #ff4350;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  color: normal-text-color;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child,
.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
  border-bottom: 1px solid #e0e0e0;
}
.km-actionsheet .k-grid-delete,
.km-actionsheet .k-scheduler-delete,
.km-pane-wrapper .k-scheduler-delete,
.km-pane-wrapper .k-filter-menu .k-button[type=reset] {
  color: #fff;
  border-color: red;
  background-color: red;
}
.km-actionsheet .k-grid-delete:active,
.km-actionsheet .k-scheduler-delete:active,
.km-pane-wrapper .k-scheduler-delete:active,
.km-pane-wrapper .k-filter-menu .k-button[type=reset]:active {
  background-color: #990000;
}
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  opacity: .4;
}
/* IE will ignore the above selectors if these are added too */
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  background-image: url('Nova/sprite.png');
  border-color: transparent;
}
.k-mobile-list .k-item > .k-link:active,
.k-mobile-list .k-item > .k-label:active,
.k-mobile-list .k-edit-label.k-check:active,
.k-mobile-list .k-recur-view .k-check:active {
  color: #888e90;
  background-color: #f5f6f6;
  border-color: #888e90;
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
  border-top-color: transparent;
}
/* Responsive styles */
@media only screen and (max-width: 1450px) {
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-safari {
    /* Responsive Scheduler */
  }
  .k-webkit .k-scheduler-toolbar .k-nav-current,
  .k-ff .k-scheduler-toolbar .k-nav-current,
  .k-ie11 .k-scheduler-toolbar .k-nav-current,
  .k-safari .k-scheduler-toolbar .k-nav-current {
    margin: 0;
  }
}
@media only screen and (max-width: 1400px) {
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-safari {
    /* Responsive Scheduler */
  }
  .k-webkit .k-nav-current > .k-link .k-lg-date-format,
  .k-ff .k-nav-current > .k-link .k-lg-date-format,
  .k-ie11 .k-nav-current > .k-link .k-lg-date-format,
  .k-safari .k-nav-current > .k-link .k-lg-date-format {
    max-width: 100px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }
  .k-webkit .k-nav-current > .k-link .k-sm-date-format,
  .k-ff .k-nav-current > .k-link .k-sm-date-format,
  .k-ie11 .k-nav-current > .k-link .k-sm-date-format,
  .k-safari .k-nav-current > .k-link .k-sm-date-format {
    display: none;
  }
  .k-webkit .k-scheduler-toolbar .k-link,
  .k-ff .k-scheduler-toolbar .k-link,
  .k-ie11 .k-scheduler-toolbar .k-link,
  .k-safari .k-scheduler-toolbar .k-link {
    padding: 0 .5em;
  }
}
@media only screen and (max-width: 1420px) {
  .k-webkit .k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar,
  .k-ff .k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar,
  .k-ie11 .k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar,
  .k-safari .k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-safari {
    /* Responsive Pager */
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views {
    right: 13px;
    top: 0;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view,
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view:hover,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view:hover,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view:hover,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view:hover,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    background-position: 50% 50%;
    color: #fff;
    background-color: #ff4350;
    border-color: #ff4350;
    text-align: right;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li {
    border-radius: 0;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover {
    background-color: #f5f6f6;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-selected,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-selected,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-selected,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-selected,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-selected,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-selected,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-selected,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-selected {
    color: #fff;
    background-color: #ff4350;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover .k-link,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover .k-link,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover .k-link,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > .k-state-hover .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > .k-state-hover .k-link {
    background-color: transparent;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li.k-current-view .k-link,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li.k-current-view .k-link,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li.k-current-view .k-link,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li.k-current-view .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view .k-link {
    color: #ffffff;
  }
  .k-webkit .k-scheduler-toolbar > ul li:first-child,
  .k-ff .k-scheduler-toolbar > ul li:first-child,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child,
  .k-safari .k-scheduler-toolbar > ul li:first-child,
  .k-webkit .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ff .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-safari .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li .k-link {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link {
    min-width: 20px;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-link,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-link,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-link,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-link {
    color: #00acc1;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-state-selected .k-link,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-state-selected .k-link,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-state-selected .k-link,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded .k-state-selected .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-state-selected .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-state-selected .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-state-selected .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded .k-state-selected .k-link {
    color: #fff;
  }
  .k-webkit .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ff .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ie11 .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-safari .k-scheduler-views > li.k-state-selected > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 0.333em;
    width: 1.333em;
    height: 1.333em;
    opacity: 0.45;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after {
    background-position: -14px -30px;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px 1px 0 1px;
    border-style: solid;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px;
    background-image: none;
  }
  .k-webkit .k-pager-wrap .k-pager-nav,
  .k-ff .k-pager-wrap .k-pager-nav,
  .k-ie11 .k-pager-wrap .k-pager-nav,
  .k-safari .k-pager-wrap .k-pager-nav,
  .k-webkit .k-pager-input,
  .k-ff .k-pager-input,
  .k-ie11 .k-pager-input,
  .k-safari .k-pager-input {
    display: inline-block;
    vertical-align: top;
  }
  .k-webkit .k-pager-numbers,
  .k-ff .k-pager-numbers,
  .k-ie11 .k-pager-numbers,
  .k-safari .k-pager-numbers,
  .k-webkit .k-grid .k-pager-numbers,
  .k-ff .k-grid .k-pager-numbers,
  .k-ie11 .k-grid .k-pager-numbers,
  .k-safari .k-grid .k-pager-numbers {
    position: absolute;
    left: 5.6em;
    display: -moz-inline-flex;
    display: -webkit-inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    overflow: visible;
    height: auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
  }
  .k-webkit .k-pager-numbers:hover,
  .k-ff .k-pager-numbers:hover,
  .k-ie11 .k-pager-numbers:hover,
  .k-safari .k-pager-numbers:hover,
  .k-webkit .k-pager-wrap .k-pager-numbers li:hover,
  .k-ff .k-pager-wrap .k-pager-numbers li:hover,
  .k-ie11 .k-pager-wrap .k-pager-numbers li:hover,
  .k-safari .k-pager-wrap .k-pager-numbers li:hover {
    background-color: #f5f6f6;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded,
  .k-webkit .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .k-grid .k-pager-numbers.k-state-expanded {
    -webkit-transform: translatey(-100%);
    -ms-transform: translatey(-100%);
        transform: translatey(-100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-pager-numbers,
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers {
    left: 50%;
    -ms-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-ff .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-ie11 .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-safari .km-pane-wrapper .k-pager-numbers.k-state-expanded,
  .k-webkit .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-ff .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-ie11 .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded,
  .k-safari .km-pane-wrapper .k-grid .k-pager-numbers.k-state-expanded {
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
        transform: translate(-50%, -100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-link,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-link,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-pager-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .k-webkit .k-rtl .k-pager-numbers,
  .k-ff .k-rtl .k-pager-numbers,
  .k-ie11 .k-rtl .k-pager-numbers,
  .k-safari .k-rtl .k-pager-numbers,
  .k-webkit .k-rtl .k-grid .k-pager-numbers,
  .k-ff .k-rtl .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers,
  .k-safari .k-rtl .k-grid .k-pager-numbers {
    right: 5.6em;
    width: 5.15em;
  }
  .k-webkit .k-pager-numbers .k-current-page,
  .k-ff .k-pager-numbers .k-current-page,
  .k-ie11 .k-pager-numbers .k-current-page,
  .k-safari .k-pager-numbers .k-current-page,
  .k-webkit .k-grid .k-pager-numbers .k-current-page,
  .k-ff .k-grid .k-pager-numbers .k-current-page,
  .k-ie11 .k-grid .k-pager-numbers .k-current-page,
  .k-safari .k-grid .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
  }
  .k-webkit .k-pager-numbers li:not(.k-current-page),
  .k-ff .k-pager-numbers li:not(.k-current-page),
  .k-ie11 .k-pager-numbers li:not(.k-current-page),
  .k-safari .k-pager-numbers li:not(.k-current-page) {
    display: none;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link {
    width: 3.8em;
    line-height: 2.564em;
    padding: 0 .429em 0 0.8em;
    border-radius: 3px;
    background-position: 50% 50%;
    background-color: #fff;
    border: 1px solid transparent;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:hover,
  .k-ff .k-pager-numbers .k-current-page .k-link:hover,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:hover,
  .k-safari .k-pager-numbers .k-current-page .k-link:hover {
    background-color: #f5f6f6;
  }
  .k-webkit .k-pager-numbers .k-current-page:hover .k-link,
  .k-ff .k-pager-numbers .k-current-page:hover .k-link,
  .k-ie11 .k-pager-numbers .k-current-page:hover .k-link,
  .k-safari .k-pager-numbers .k-current-page:hover .k-link {
    border-top: 0;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:after,
  .k-ff .k-pager-numbers .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:after,
  .k-safari .k-pager-numbers .k-current-page .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.6em;
    width: 1.333em;
    height: 1.333em;
    opacity: .45;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link:after {
    background-position: -15px 0;
    opacity: 1;
  }
  .k-webkit .k-pager-numbers + .k-link,
  .k-ff .k-pager-numbers + .k-link,
  .k-ie11 .k-pager-numbers + .k-link,
  .k-safari .k-pager-numbers + .k-link {
    margin-left: 5.4em;
  }
  .k-webkit .k-rtl .k-pager-numbers + .k-link,
  .k-ff .k-rtl .k-pager-numbers + .k-link,
  .k-ie11 .k-rtl .k-pager-numbers + .k-link,
  .k-safari .k-rtl .k-pager-numbers + .k-link {
    margin-right: 5.4em;
    margin-left: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-wrap .k-pager-numbers .k-link,
  .k-ff .k-pager-wrap .k-pager-numbers .k-link,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-link,
  .k-safari .k-pager-wrap .k-pager-numbers .k-link {
    color: #00acc1;
    display: block;
    margin-top: 0;
    margin-right: 0;
    padding: 1px 5px 1px .8em;
    text-align: left;
    border-top: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-pager-nav,
  .k-ff .k-pager-wrap .k-pager-numbers .k-pager-nav,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-pager-nav,
  .k-safari .k-pager-wrap .k-pager-numbers .k-pager-nav {
    color: #32364c;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-wrap .k-pager-numbers .k-state-selected {
    color: #fff;
    background-color: #ff4350;
    border-color: #ff4350;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 2px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #e0e0e0;
    background-color: #fff;
    -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page {
    margin: -2.2em -1px 0;
    padding: 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link {
    border-radius: 0 0 3px 3px;
    border-top: 0;
    color: #fff;
    background-color: #ff4350;
    border-color: #ff4350;
  }
  .k-webkit .k-pager-numbers.k-state-expanded li,
  .k-ff .k-pager-numbers.k-state-expanded li,
  .k-ie11 .k-pager-numbers.k-state-expanded li,
  .k-safari .k-pager-numbers.k-state-expanded li {
    display: inline-block;
  }
  .k-webkit .k-gantt-toolbar > ul.k-gantt-views,
  .k-ff .k-gantt-toolbar > ul.k-gantt-views,
  .k-ie11 .k-gantt-toolbar > ul.k-gantt-views,
  .k-safari .k-gantt-toolbar > ul.k-gantt-views {
    top: 0;
  }
}
@media only screen and (max-width: 678px) {
  .k-webkit .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-safari .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 530px) {
  .k-webkit .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-safari .k-pager-sizes {
    display: none;
  }
}
@media only screen and (max-width: 350px) {
  .k-webkit .k-pager-refresh,
  .k-ff .k-pager-refresh,
  .k-ie11 .k-pager-refresh,
  .k-safari .k-pager-refresh {
    display: none;
  }
}
/* Stock Chart */
.k-chart .k-mask {
  background-color: #fff;
  filter: alpha(opacity=68);
  -moz-opacity: 0.68;
  opacity: 0.68;
}
.k-chart .k-selection {
  border-color: #e0e0e0;
}
.k-chart .k-handle {
  width: 15px;
  height: 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
.k-chart .k-leftHandle {
  left: -8px;
}
.k-chart .k-rightHandle {
  right: -8px;
}
.k-chart .k-handle:hover {
  background-color: #f5f6f6;
}
.k-chart .k-navigator-hint .k-tooltip {
  border: #e0e0e0;
  -webkit-box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #32364c;
}
.k-chart .k-navigator-hint .k-scroll {
  background: #fff;
  height: 4px;
}
.k-chart-tooltip {
  background-image: none;
}
/* Map */
.k-map .k-header {
  background-color: #fff;
  border-color: #e0e0e0;
}
.k-map .k-marker {
  background-image: url("Nova/markers.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    background-image: url("Nova/markers_2x.png");
  }
}
.k-map .k-attribution {
  color: #666666;
}
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #fff;
}
.k-spreadsheet-top-corner,
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #fff;
  background-image: none;
  color: #000000;
  border-color: #cccccc;
}
.k-spreadsheet-top-corner {
  border-color: #cccccc;
}
.k-spreadsheet-top-corner:after {
  border-color: transparent #cccccc #cccccc transparent;
}
.k-spreadsheet-pane {
  border-color: #cccccc;
}
.k-spreadsheet-pane .k-spreadsheet-vaxis,
.k-spreadsheet-pane .k-spreadsheet-haxis {
  border-color: #e6e6e6;
}
.k-spreadsheet-pane .k-spreadsheet-column-header,
.k-spreadsheet-pane .k-spreadsheet-row-header {
  border-color: #cccccc;
}
.k-spreadsheet-pane .k-spreadsheet-merged-cell {
  background-color: #fff;
}
.k-spreadsheet-pane .k-selection-partial,
.k-spreadsheet-pane .k-selection-full {
  border-color: rgba(38, 198, 218, 0.2);
  background-color: rgba(38, 198, 218, 0.2);
}
.k-spreadsheet-pane .k-filter-range {
  border-color: #26c6da;
}
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-full {
  border-bottom-color: #26c6da;
}
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-full {
  border-right-color: #26c6da;
}
.k-auto-fill,
.k-spreadsheet-selection {
  border-color: #26c6da;
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #26c6da;
          box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #26c6da;
}
.k-spreadsheet-selection {
  background-color: rgba(38, 198, 218, 0.2);
}
.k-spreadsheet-active-cell {
  border-color: #26c6da !important;
  background-color: #fff;
}
.k-spreadsheet-active-cell.k-single {
  background-color: #fff;
}
.k-spreadsheet > .k-spreadsheet-formula-bar {
  background-color: #fff;
  border-color: #fff #fff #cccccc;
}
.k-spreadsheet > .k-spreadsheet-formula-bar:before {
  border-color: #cccccc;
}
.k-spreadsheet > .k-spreadsheet-formula-bar:after {
  border-color: #fff;
}
.k-spreadsheet .k-spreadsheet-formula-input {
  background-color: #fff;
  color: #32364c;
}
.k-spreadsheet .k-resize-handle,
.k-spreadsheet .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-marker {
  background-color: #ff4350;
}
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-marker {
  background-color: #ff4350;
}
.k-spreadsheet .k-single-selection::after {
  background-color: #26c6da;
  border-color: #fff;
}
.k-spreadsheet .k-auto-fill-punch {
  background-color: rgba(255, 255, 255, 0.5);
}
.k-spreadsheet .k-single-selection.k-dim-auto-fill-handle::after {
  background-color: rgba(38, 198, 218, 0.5);
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
  border-color: #e0e0e0;
}
.k-spreadsheet-filter {
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1px #e6e6e6;
          box-shadow: inset 0 0 0 1px #e6e6e6;
}
.k-spreadsheet-filter.k-state-active {
  color: #fff;
  background-color: #ff4350;
}
.k-spreadsheet-filter:hover {
  color: #888e90;
  background: #f5f6f6;
  border-color: #e0e3e3;
}
.k-action-window .k-action-buttons {
  border-color: #e0e0e0;
}
.k-spreadsheet-sample {
  color: #747ba3;
}
.k-state-selected .k-spreadsheet-sample {
  color: inherit;
}
.k-spreadsheet-window .k-list-wrapper,
.k-spreadsheet-window .k-list {
  border-color: #e0e0e0;
  border-radius: 0;
}
.k-spreadsheet-window .export-config,
.k-spreadsheet-window .k-edit-field > .k-orientation-label {
  border-color: #e0e0e0;
}
.k-spreadsheet-window .k-edit-field > input[type="radio"]:checked + .k-orientation-label {
  background-image: none;
  background-color: #ff4350;
  color: #ffffff;
}
.k-spreadsheet-window .k-page-orientation {
  border-color: #9da2a4;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.k-spreadsheet-window .k-page-orientation:before {
  background: #fff;
  border-color: transparent;
  border-bottom-color: #9da2a4;
  border-left-color: #9da2a4;
}
.k-spreadsheet-window .k-margins-horizontal,
.k-spreadsheet-window .k-margins-vertical {
  background: transparent;
  border-color: #e0e0e0;
}
.k-spreadsheet-toolbar.k-toolbar .k-button-group .k-button {
  border-radius: 0;
}
.k-spreadsheet-toolbar > .k-widget,
.k-spreadsheet-toolbar > .k-button,
.k-spreadsheet-toolbar > .k-button-group {
  border-radius: 0;
}
.k-spreadsheet-toolbar > .k-separator {
  border-color: #e0e0e0;
}
.k-spreadsheet-toolbar .k-overflow-anchor {
  border-radius: 0;
}
.k-spreadsheet-popup {
  border-radius: 0;
}
.k-spreadsheet-popup .k-separator {
  background-color: #e0e0e0;
}
.k-spreadsheet-popup .k-button {
  background-color: transparent;
}
.k-spreadsheet-popup .k-button:hover {
  background-color: #f5f6f6;
}
.k-spreadsheet-popup .k-state-active {
  background-color: #ff4350;
  color: #000000;
}
.k-spreadsheet-popup .k-state-active:hover {
  background-color: #ff1021;
}
.k-spreadsheet-filter-menu .k-details {
  border-color: #e0e0e0;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-color: #fff;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  background-color: #fff;
  border-color: #e0e0e0;
  border-radius: 0 0 0 0;
}
.k-syntax-ref {
  color: #ff8822;
}
.k-syntax-num {
  color: #0099ff;
}
.k-syntax-func {
  font-weight: bold;
}
.k-syntax-str {
  color: #38b714;
}
.k-syntax-error {
  color: red;
}
.k-syntax-bool {
  color: #a9169c;
}
.k-syntax-startexp {
  font-weight: bold;
}
.k-syntax-paren-match {
  background-color: #caf200;
}
.k-series-a {
  border-color: #ff4350;
  background-color: rgba(255, 67, 80, 0.15);
}
.k-series-b {
  border-color: #ff9ea5;
  background-color: rgba(255, 158, 165, 0.15);
}
.k-series-c {
  border-color: #00acc1;
  background-color: rgba(0, 172, 193, 0.15);
}
.k-series-d {
  border-color: #80deea;
  background-color: rgba(128, 222, 234, 0.15);
}
.k-series-e {
  border-color: #ffbf46;
  background-color: rgba(255, 191, 70, 0.15);
}
.k-series-f {
  border-color: #ffd78c;
  background-color: rgba(255, 215, 140, 0.15);
}
.k-spreadsheet-sheets-remove:hover .k-icon {
  color: #cc2222;
}
.k-spreadsheet-formula-list .k-state-focused {
  background-color: #ff4350;
  color: #fff;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .k-icon.k-font-icon {
    background-image: none;
  }
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button,
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #ffffff;
  border-radius: 0;
  line-height: 2.6em;
  width: 3em;
}
.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button:hover,
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button:hover {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#202333), to(#081421));
  background-image: none, -webkit-linear-gradient(top, #202333 0%, #081421 100%);
  background-image: none, linear-gradient(to bottom, #202333 0%, #081421 100%);
  border-color: #2e3350;
}
.k-spreadsheet .k-spreadsheet-sheets-bar .k-button {
  padding-top: .5em;
  padding-bottom: .5em;
  line-height: 2.2em;
}
.k-spreadsheet .k-spreadsheet-sheets-bar-add {
  left: 0;
  bottom: 0;
}
.k-spreadsheet .k-spreadsheet-sheets-remove {
  margin: 0 0 0 -1em;
}
.k-spreadsheet-sheets-items {
  background-image: none;
  background-image: none, -webkit-gradient(linear, left top, left bottom, from(#303553), to(#072138));
  background-image: none, -webkit-linear-gradient(top, #303553 0%, #072138 100%);
  background-image: none, linear-gradient(to bottom, #303553 0%, #072138 100%);
}
.k-spreadsheet-sheets-items .k-state-default .k-link,
.k-spreadsheet-tabstrip .k-state-default .k-link {
  color: #ffffff;
}
.k-spreadsheet-sheets-items .k-item.k-state-hover,
.k-spreadsheet-tabstrip .k-item.k-state-hover,
.k-spreadsheet-sheets-items .k-item.k-state-active,
.k-spreadsheet-tabstrip .k-item.k-state-active,
.k-spreadsheet-sheets-items .k-item.k-state-focused,
.k-spreadsheet-tabstrip .k-item.k-state-focused {
  background-color: transparent;
}
.k-spreadsheet-sheets-items .k-item.k-state-hover .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-hover .k-link,
.k-spreadsheet-sheets-items .k-item.k-state-active .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-active .k-link,
.k-spreadsheet-sheets-items .k-item.k-state-focused .k-link,
.k-spreadsheet-tabstrip .k-item.k-state-focused .k-link {
  color: #ffffff;
}
.k-spreadsheet-sheets-items .k-state-active .k-link,
.k-spreadsheet-tabstrip .k-state-active .k-link {
  color: #ffffff;
}
.k-spreadsheet-toolbar {
  background: #f5f5f5;
}
.k-spreadsheet-toolbar > .k-button {
  border-color: transparent;
}
